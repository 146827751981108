import { InputField as CreditasInputField } from '@creditas/form-elements/dist/InputField'
import { styled } from '@creditas-ui/system'

export const StyledInputField = styled(CreditasInputField)`
  width: 100%;
`

export const Container = styled.div`
  cursor: text;
  margin-bottom: 24px;
`
