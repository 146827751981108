import React from 'react'
import { bool, func, oneOf, string } from 'prop-types'

import NumberFormat from 'react-number-format'

import { Container, StyledInputField } from './InputField.style'

const propTypes = {
  'data-testid': string,
  defaultValue: string,
  disabled: bool,
  helperText: string,
  inputType: oneOf(['text', 'email', 'number', 'search', 'tel', 'password']),
  label: string,
  mask: oneOf(['cpf', 'cnpj', 'phone', 'currency']),
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  required: bool,
  template: oneOf(['Template01', 'Template02']),
  variant: oneOf(['default', 'success', 'warning', 'danger', 'loading']),
}

const defaultProps = {
  inputType: 'text',
  template: 'Template01',
}

const MASK_OPTIONS = {
  cpf: {
    format: '###.###.###-##',
  },
  cnpj: {
    format: '##.###.###/####-##',
  },
  phone: {
    format: '(##) ####-#####',
  },
  currency: {
    thousandSeparator: '.',
    decimalScale: 2,
    prefix: 'R$ ',
  },
}

const InputField = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestId,
    defaultValue,
    disabled,
    helperText,
    inputType,
    label,
    mask,
    name,
    onBlur,
    onChange,
    onFocus,
    required,
    template,
    variant,
    ...others
  } = props
  const isMaskedInput = () => mask || inputType === 'number'

  const maskProps = {
    mask: '',
    customInput: StyledInputField,
    isNumericString: true,
    getInputRef: ref,
    decimalSeparator: ',',
    ...MASK_OPTIONS[mask],
  }

  const Component = isMaskedInput() ? NumberFormat : StyledInputField
  const aditionalProps = isMaskedInput() ? maskProps : {}
  const type = isMaskedInput() ? 'tel' : inputType

  return (
    <Container>
      <Component
        name={name}
        label={label}
        helperText={helperText}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        variant={variant}
        ref={ref}
        data-testid={dataTestId}
        {...others}
        type={type}
        autocomplete="off"
        defaultValue={defaultValue}
        {...aditionalProps}
      />
    </Container>
  )
})

InputField.propTypes = propTypes
InputField.defaultProps = defaultProps
InputField.displayName = 'InputField'

export default InputField
